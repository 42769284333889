// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['view', 'handle', 'opened', 'closed'];
  static values = { initial: String };

  connect() {
    this.handleTarget.addEventListener('click', () => this.toggle());
    if (this.initialValue === 'closed') {
      this.viewTarget.hidden = true;
    }
    this.update();
  }

  toggle() {
    this.viewTarget.hidden = !this.viewTarget.hidden;
    this.update();
  }

  update() {
    if (this.viewTarget.hidden) {
      this.openedTarget.hidden = true;
      this.closedTarget.hidden = false;
    } else {
      this.openedTarget.hidden = false;
      this.closedTarget.hidden = true;
    }
  }
}
