// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';
import Rails from '@rails/ujs';
import Turbolinks from 'turbolinks';
import LocalTime from 'local-time';
import feather from 'feather-icons';
import Dropdown from 'bootstrap.native/dist/components/dropdown-native.esm';
import Tab from 'bootstrap.native/dist/components/tab-native.esm';
import Tooltip from 'bootstrap.native/dist/components/tooltip-native.esm';
import Collapse from 'bootstrap.native/dist/components/collapse-native.esm';
import Popover from 'bootstrap.native/dist/components/popover-native.esm';

Rails.start();
Turbolinks.start();
LocalTime.start();

const application = Application.start();
const context = require.context(
  'admin/controllers',
  true,
  /_controller\.jsx?$/
);
application.load(definitionsFromContext(context));

// INIT
document.addEventListener('turbolinks:load', function () {
  feather.replace();
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-toggle="dropdown"]'),
    function (element) {
      new Dropdown(element);
    }
  );
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-toggle="tab"]'),
    function (element) {
      new Tab(element);
    }
  );
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-toggle="tooltip"]'),
    function (element) {
      new Tooltip(element);
    }
  );
  // Array.prototype.forEach.call(document.querySelectorAll('[data-spy="affix"]'), function(element){ new BSN.Affix(element) });
  // Array.prototype.forEach.call(document.querySelectorAll('[data-dismiss="alert"]'), function(element){ new BSN.Alert(element) });
  // Array.prototype.forEach.call(document.querySelectorAll('[data-toggle="buttons"]'), function(element){ new BSN.Button(element) });
  // Array.prototype.forEach.call(document.querySelectorAll('[data-ride="carousel"]'), function(element){ new BSN.Carousel(element) });
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-toggle="collapse"]'),
    function (element) {
      new Collapse(element);
    }
  );
  // Array.prototype.forEach.call(document.querySelectorAll('[data-toggle="modal"]'), function(element){ new BSN.Modal(element) });
  Array.prototype.forEach.call(
    document.querySelectorAll('[data-toggle="popover"]'),
    function (element) {
      new Popover(element);
    }
  );
  // Array.prototype.forEach.call(document.querySelectorAll('[data-spy="scroll"]'), function(element){ new BSN.ScrollSpy(element) });
});
