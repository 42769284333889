// Additional static ranges used in React Date Range Picker
// Referenced in date_range_controller.jsx
import {
  addDays,
  endOfDay,
  startOfDay,
  startOfMonth,
  endOfMonth,
  addMonths,
  startOfWeek,
  endOfWeek,
  startOfYear,
  endOfYear,
  addYears,
  isSameDay,
} from 'date-fns';

function isSelectedRange(range) {
  return (
    isSameDay(range.startDate, this.range().startDate) &&
    isSameDay(range.endDate, this.range().endDate)
  );
}

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: startOfMonth(addMonths(new Date(), -1)),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
  startOfYear: startOfYear(new Date()),
  endOfYear: endOfYear(new Date()),
  startOfLastYear: startOfYear(addYears(new Date(), -1)),
  endOfLastYear: endOfYear(addYears(new Date(), -1)),
};

const staticRangeHandler = {
  range: {},
  isSelected(range) {
    const definedRange = this.range();
    return (
      isSameDay(range.startDate, definedRange.startDate) &&
      isSameDay(range.endDate, definedRange.endDate)
    );
  },
};

export function createStaticRanges(ranges) {
  return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
}

export const staticRanges = createStaticRanges([
  {
    label: 'Today',
    range: () => ({
      startDate: defineds.startOfToday,
      endDate: defineds.endOfToday,
    }),
  },
  {
    label: 'Yesterday',
    range: () => ({
      startDate: defineds.startOfYesterday,
      endDate: defineds.endOfYesterday,
    }),
  },

  {
    label: 'This Week',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
  {
    label: 'Last Week',
    range: () => ({
      startDate: defineds.startOfLastWeek,
      endDate: defineds.endOfLastWeek,
    }),
  },
  {
    label: 'Last 7 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 7),
      endDate: new Date(),
    }),
  },
  {
    label: 'This Month',
    range: () => ({
      startDate: defineds.startOfMonth,
      endDate: defineds.endOfMonth,
    }),
  },
  {
    label: 'Last Month',
    range: () => ({
      startDate: defineds.startOfLastMonth,
      endDate: defineds.endOfLastMonth,
    }),
  },
  {
    label: 'Last 30 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 60 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 60),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 90 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 90),
      endDate: new Date(),
    }),
  },
  {
    label: '97-7 days ago',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 97),
      endDate: new Date().setDate(new Date().getDate() - 7),
    }),
  },
  {
    label: '120-30 days ago',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 120),
      endDate: new Date().setDate(new Date().getDate() - 30),
    }),
  },
  {
    label: 'Last 180 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 180),
      endDate: new Date(),
    }),
  },
  {
    label: 'This Year',
    range: () => ({
      startDate: defineds.startOfYear,
      endDate: defineds.endOfYear,
    }),
  },
  {
    label: 'Last Year',
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfLastYear,
    }),
  },
  {
    label: 'Last Year To Date',
    range: () => ({
      startDate: defineds.startOfLastYear,
      endDate: defineds.endOfYear,
    }),
  },
  {
    label: 'Last 365 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 365),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 730 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 730),
      endDate: new Date(),
    }),
  },
  {
    label: 'Last 1095 Days',
    range: () => ({
      startDate: new Date().setDate(new Date().getDate() - 1095),
      endDate: new Date(),
    }),
  },
  // {
  //   label: 'Month to Date',
  //   range: () => ({
  //     startDate: startOfMonth(new Date()),
  //     endDate: new Date(),
  //   })
  // },
  // {
  //   label: 'Year to Date',
  //   range: () => ({
  //     startDate: startOfYear(new Date()),
  //     endDate: new Date(),
  //   })
  // },
]);

export default { staticRanges };
