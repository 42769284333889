import { Controller } from 'stimulus';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { useState } from 'react';
import { addDays } from 'date-fns';
import { DateRangePicker } from 'react-date-range';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

function DatePicker() {
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection',
    },
  ]);
  return (
    <DateRangePicker
      onChange={(item) => setState([item.selection])}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
    />
  );
}

export default class extends Controller {
  connect() {
    this.root = createRoot(this.element);
    this.root.render(<DatePicker />);

    document.addEventListener('turbolinks:before-cache', this.unmountComponent);
  }

  disconnect() {
    document.removeEventListener(
      'turbolinks:before-cache',
      this.unmountComponent
    );
  }

  unmountComponent = () => {
    this.root.unmount();
    this.root = null;
  };
}
