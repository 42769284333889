import React from 'react';
import BigNumber from 'bignumber.js';
import Input from '@salesforce/design-system-react/components/input';

// placeholder

const isPresent = (value) => value !== undefined && value !== null;

export default class extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: isPresent(props.value)
        ? BigNumber(props.value).times(100).toFixed()
        : '',
    };
  }

  render() {
    const { name, value, props } = this.props;
    return (
      <div>
        <Input
          fixedTextRight="%"
          {...props}
          onChange={(event, data) => {
            this.setState({ value: data.value });
          }}
          value={this.state.value}
        />
        <input
          type="hidden"
          name={name}
          value={
            isPresent(this.state.value)
              ? BigNumber(this.state.value).div(100).toFixed()
              : ''
          }
        />
      </div>
    );
  }
}
