import Radio from '@salesforce/design-system-react/components/radio';
import RadioGroup from '@salesforce/design-system-react/components/radio-group';

// scope
// checked: AddableType|AddableId
// values = { [AddableType|AddableId]: name/label }
export default class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: props.checked,
    };
  }

  render() {
    const { scope, values, ...props } = this.props;
    const outputs = (this.state.checked || '|').split('|');
    return (
      <div>
        <RadioGroup
          onChange={(event) => this.setState({ checked: event.target.value })}
        >
          {Object.entries(values).map(([key, value]) => (
            <Radio
              key={key}
              id={key}
              labels={{ label: value }}
              value={key}
              checked={this.state.checked === key}
              variant="base"
            />
          ))}
        </RadioGroup>
        <input
          type="hidden"
          name={`${scope}[addable_type]`}
          value={outputs[0]}
        />
        <input type="hidden" name={`${scope}[addable_id]`} value={outputs[1]} />
      </div>
    );
  }
}
