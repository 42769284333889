import { Controller } from 'stimulus';
import React from 'react';
import { createRoot } from 'react-dom/client';

import { useState } from 'react';
import parseISO from 'date-fns/parseISO';
import { DateRangePicker } from 'react-date-range';
import { staticRanges } from './date_static_ranges';

import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import './../styles/calendar.scss';
import subDays from 'date-fns/subDays';
import formatISO from 'date-fns/formatISO';

function DatePicker({ startDate, endDate, onChange }) {
  const [state, setState] = useState([
    {
      startDate,
      endDate,
      key: 'selection',
    },
  ]);
  const handleChange = ({ selection }) => {
    setState([selection]);
    onChange(selection);
  };
  return (
    <DateRangePicker
      onChange={handleChange}
      showSelectionPreview={true}
      moveRangeOnFirstSelection={false}
      months={2}
      ranges={state}
      direction="horizontal"
      staticRanges={[...staticRanges]}
    />
  );
}

function parseToDate(value, defaultValue) {
  if (value) {
    return parseISO(value);
  }
  return defaultValue;
}

export default class extends Controller {
  static targets = ['content', 'trigger', 'start', 'end', 'form'];

  connect() {
    this.triggerTarget.addEventListener('click', (event) => this.toggle(event));

    const startDate = parseToDate(
      this.startTarget.value,
      subDays(new Date(), 7)
    );
    const endDate = parseToDate(this.endTarget.value, new Date());

    this.root = createRoot(this.contentTarget);
    this.root.render(
      <DatePicker
        startDate={startDate}
        endDate={endDate}
        onChange={(selection) => this.onChange(selection)}
      />
    );

    document.addEventListener('turbolinks:before-cache', this.unmountComponent);
  }

  disconnect() {
    document.removeEventListener(
      'turbolinks:before-cache',
      this.unmountComponent
    );
  }

  unmountComponent = () => {
    this.root.unmount();
    this.root = null;
  };

  onChange({ startDate, endDate }) {
    this.startTarget.value = formatISO(startDate, { representation: 'date' });
    this.endTarget.value = formatISO(endDate, { representation: 'date' });
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
    }
    if (this.contentTarget.hidden) {
      this.contentTarget.hidden = false;
      this.triggerTarget.innerHTML = 'Done';
      this.triggerTarget.classList.add('btn-primary');
      this.triggerTarget.classList.remove('btn-outline-secondary');
    } else {
      const startDate = parseToDate(
        this.startTarget.value,
        subDays(new Date(), 7)
      );
      const endDate = parseToDate(this.endTarget.value, new Date());
      const str = `${formatISO(startDate, {
        representation: 'date',
      })} to ${formatISO(endDate, { representation: 'date' })}`;

      this.contentTarget.hidden = true;
      this.triggerTarget.innerHTML = str;
      this.triggerTarget.classList.remove('btn-primary');
      this.triggerTarget.classList.add('btn-outline-secondary');
      this.formTarget.submit();
    }
  }
}
