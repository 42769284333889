// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="hello">
//   <h1 data-target="hello.output"></h1>
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['expander', 'collapser'];

  connect() {
    this.expanderTarget.addEventListener('click', (event) =>
      this.expand(event)
    );
    this.collapserTarget.addEventListener('click', (event) =>
      this.collapse(event)
    );
    // this.collapse();
  }

  expand(event) {
    if (event) {
      event.preventDefault();
    }
    this.expanderTarget.hidden = true;
    this.collapserTarget.hidden = false;
    this.element.nextElementSibling.hidden = false;
  }
  collapse(event) {
    if (event) {
      event.preventDefault();
    }
    this.expanderTarget.hidden = false;
    this.collapserTarget.hidden = true;
    this.element.nextElementSibling.hidden = true;
  }
}
