// Visit The Stimulus Handbook for more details
// https://stimulusjs.org/handbook/introduction
//
// This example controller works with specially annotated HTML like:
//
// <div data-controller="input-parent-child">
//   <select data-target="input">
//     <option></option>
//   </select>
//   <div data-target="child" data-parent=""></div>
// </div>

import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['input', 'child'];

  connect() {
    this.inputTarget.addEventListener('change', () => this.update());
    this.update();
  }

  update() {
    this.childTargets.forEach((childTarget) => {
      const parents = childTarget.dataset.parent.split('|');
      if (childTarget && parents.includes(this.value())) {
        childTarget.hidden = false;
        Array.from(
          childTarget.querySelectorAll('input,select,textarea')
        ).forEach((element) => (element.disabled = false));
      } else {
        childTarget.hidden = true;
        if (childTarget.selected) {
          childTarget.parentElement.value = '';
        }
        Array.from(
          childTarget.querySelectorAll('input,select,textarea')
        ).forEach((element) => (element.disabled = true));
      }
    });
  }

  value() {
    const option = this.inputTarget.options[this.inputTarget.selectedIndex];
    if (option) {
      const value = option.dataset.key || option.value;
      return value;
    }
    return undefined;
  }
}
