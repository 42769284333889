// @see https://medium.com/companydev/turbolinks-stimulus-and-react-oh-my-83541d69c41
// @see https://gist.githubusercontent.com/chadwilken/eed0b9f547568155680c612f08add20e/raw/b6940ef560a610309a0101c1dd0423fcdd167357/react-controller.js
import { Controller } from 'stimulus';
import React from 'react';
import { createRoot } from 'react-dom/client';
import '../components';
// import PropTypes from 'prop-types';

// Load Salesforce Icon Settings
// https://github.com/salesforce/design-system-react/blob/master/components/icon-settings/index.jsx
import IconSettings from '@salesforce/design-system-react/components/icon-settings';
import { onRequestIconPath } from 'shared/lib/slds';

export default class extends Controller {
  connect() {
    // console.log('React Controller: ', this.data.get('component'));
    const Component = window.ReactComponents[this.data.get('component')];
    let props = this.data.get('props') || {};
    if (typeof props === 'string') {
      props = JSON.parse(props);
    }

    this.root = createRoot(this.element);
    this.root.render(
      <div className="slds">
        <IconSettings onRequestIconPath={onRequestIconPath}>
          <Component {...props} />
        </IconSettings>
      </div>
    );

    document.addEventListener('turbolinks:before-cache', this.unmountComponent);
  }

  disconnect() {
    document.removeEventListener(
      'turbolinks:before-cache',
      this.unmountComponent
    );
  }

  unmountComponent = () => {
    this.root.unmount();
    this.root = null;
  };
}
