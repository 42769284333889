import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['handle', 'content'];

  connect() {
    this.handleTarget.addEventListener('click', (event) => {
      this.toggle(event);
    });
  }

  toggle(event) {
    if (event) {
      event.preventDefault();
    }
    this.contentTarget.classList.toggle('show');
  }
}
