import React from 'react';
import Combobox from '@salesforce/design-system-react/components/combobox';
import comboboxFilterAndLimit from '@salesforce/design-system-react/components/combobox/filter';

// placeholder
// options
// selected

export default class extends React.Component {
  constructor(props) {
    super(props);

    const selection = (props.selected ? [props.selected] : []).map((id) =>
      props.options.find((option) => option.id == id)
    );

    this.state = {
      inputValue: '',
      selection,
    };
  }

  render() {
    return (
      <div>
        <Combobox
          events={{
            onChange: (event, { value }) => {
              this.setState({ inputValue: value });
            },
            onRequestRemoveSelectedOption: (event, data) => {
              this.setState({
                inputValue: '',
                selection: data.selection,
              });
            },
            onSubmit: (event, { value }) => {
              this.setState({
                inputValue: '',
                selection: [
                  ...this.state.selection,
                  {
                    label: value,
                  },
                ],
              });
            },
            onSelect: (event, data) => {
              this.setState({
                inputValue: '',
                selection: data.selection,
              });
            },
          }}
          labels={{
            placeholder: this.props.placeholder,
          }}
          predefinedOptionsOnly={true}
          options={comboboxFilterAndLimit({
            inputValue: this.state.inputValue,
            limit: 10,
            options: this.props.options,
            selection: this.state.selection,
          })}
          selection={this.state.selection}
          value={
            this.state.selection && this.state.selection.length
              ? this.state.selection[0].label
              : this.state.inputValue
          }
          variant="inline-listbox"
          classNameMenu="slds-lookup__list"
        />
        {this.state.selection.map((value) => {
          return (
            <input
              key={value.id}
              type="hidden"
              name={this.props.tag_name}
              value={value.id}
            />
          );
        })}
      </div>
    );
  }
}
