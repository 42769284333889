import Hello from './hello';
import Combobox from './combobox';
import Picklist from './singlepicklist';
import PercentInput from './percent_input_component';
import CurrencyInput from './currency_input_component';
import AddablePicker from './addable_picker_component';
// import '@salesforce-ux/design-system/scss/index.scss';

window.ReactComponents = window.ReactComponents || {};

window.ReactComponents['Hello'] = Hello;
window.ReactComponents['Combobox'] = Combobox;
window.ReactComponents['Picklist'] = Picklist;
window.ReactComponents['PercentInput'] = PercentInput;
window.ReactComponents['CurrencyInput'] = CurrencyInput;
window.ReactComponents['AddablePicker'] = AddablePicker;

// AUTOMATICALLY LOAD ALL COMPONENTS
// const context = require.context("components", true, /_component\.jsx$/);
// context.keys().forEach(key => {
//   const ctx = context(key);
//   const component = ctx.default;
//   const identifier = identifierForContextKey(key);
//   if (typeof component == 'function' && identifier) {
//     // TODO: Load file???
//     window.ReactComponents[identifier] = component;
//   }
// });
// function identifierForContextKey(key) {
//   const logicalName = (key.match(/^(?:\.\/)?(.+)(?:[_-]component\..+?)$/) || [])[1]
//   if (logicalName) {
//     // TODO: changeCase.pascalCase(logicalName);
//     // https://github.com/blakeembrey/change-case
//     return logicalName.replace(/_/g, "-").replace(/\//g, "--")
//   }
// }
