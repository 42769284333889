import { Controller } from 'stimulus';

export default class extends Controller {
  connect() {
    this.element.addEventListener('change', () => this.onChange());
    this.onChange();
  }
  onChange() {
    if (this.element.value === '') {
      this.hide();
    } else {
      this.show();
    }
  }
  hide() {
    document.getElementById(
      `${this.element.dataset.prefix}-appointment`
    ).style.visibility = 'hidden';
    document.getElementById(
      `${this.element.dataset.prefix}-transfer`
    ).style.visibility = 'hidden';
  }
  show() {
    document.getElementById(
      `${this.element.dataset.prefix}-appointment`
    ).style.visibility = 'visible';
    document.getElementById(
      `${this.element.dataset.prefix}-transfer`
    ).style.visibility = 'visible';
  }
}
