import actionSprite from '@salesforce-ux/design-system/assets/icons/action-sprite/svg/symbols.svg';
import customSprite from '@salesforce-ux/design-system/assets/icons/custom-sprite/svg/symbols.svg';
import doctypeSprite from '@salesforce-ux/design-system/assets/icons/doctype-sprite/svg/symbols.svg';
import standardSprite from '@salesforce-ux/design-system/assets/icons/standard-sprite/svg/symbols.svg';
import utilitySprite from '@salesforce-ux/design-system/assets/icons/utility-sprite/svg/symbols.svg';

// .replace(process.env.WEBPACKER_ASSET_HOST, '');
const defaultIconPath = (category) => {
  switch (category) {
    case 'action':
      return actionSprite;
    case 'custom':
      return customSprite;
    case 'doctype':
      return doctypeSprite;
    case 'standard':
      return standardSprite;
    case 'utility':
    default:
      return utilitySprite;
  }
};
export function onRequestIconPath({ category, name }) {
  const path = defaultIconPath(category);
  const url = new URL(path, window.location.origin);
  // url.protocol = window.location.protocol
  // url.hostname = window.location.hostname
  // url.port = window.location.port
  // const newPath = url.toString();
  const newPath = `${url.pathname}#${name}`;
  return newPath;
}
