import { Controller } from 'stimulus';
// import { fetch } from '@rails/ujs';

export default class extends Controller {
  static targets = ['type', 'ids'];

  connect() {
    console.log('CONNECTED');
    this.typeTarget.addEventListener('change', () => this.update());
    this.update();
  }

  async update() {
    const type = this.typeTarget.options[this.typeTarget.selectedIndex]?.value;
    console.log('TYPE: ', type);
    const response = await fetch(`/rules/subjects?type=${type}`, {
      headers: { accept: 'application/json' },
    });
    const data = await response.json();
    console.log('DATA: ', data);

    const prevId = this.idsTarget.value;
    console.log('PREV ID: ', prevId);
    if (data && data.length > 0) {
      this.idsTarget.innerHTML = data
        .map(
          (subject) => `<option value="${subject.id}">${subject.name}</option>`
        )
        .join('');

      if (prevId) {
        const exists = data.find((subject) => subject.id == prevId);
        if (!exists) {
          this.idsTarget.value = data[0].id;
        }
      } else {
        this.idsTarget.value = data[0].id;
      }
    } else {
      this.idsTarget.innerHTML = '';
      this.idsTarget.value = '';
    }
  }
}
