import { Controller } from 'stimulus';
import { Helper } from 'dxf';
import { polygon } from '@turf/helpers';
import centroid from '@turf/centroid';
import booleanContains from '@turf/boolean-contains';
// import { cloneDeep } from 'lodash'

function scaleSets(sets, scale = 1.0) {
  return sets.map((set) => ({
    ...set,
    lines: set.lines.map((line) => ({
      start: {
        x: line.start.x * scale,
        y: line.start.y * scale,
      },
      end: {
        x: line.end.x * scale,
        y: line.end.y * scale,
      },
    })),
  }));
}
function groupify4(lines) {
  const groups = [];
  let current = null;
  lines.forEach((line, index) => {
    if (current === null) {
      current = { index, lines: [line] };
    } else {
      // IF the last point in  current is the start point of line
      // then add line to current
      // otherwise start a new current
      const last = current.lines[current.lines.length - 1];
      if (last.end.x === line.start.x && last.end.y === line.start.y) {
        current.lines.push(line);
      } else {
        groups.push(current);
        current = { index, lines: [line] };
      }
    }
  });
  if (current) {
    groups.push(current);
    current = null;
  }
  return groups;
}
function getViewBox(entities, scale = 1.0) {
  let min = { x: Infinity, y: Infinity };
  let max = { x: -Infinity, y: -Infinity };
  entities.forEach((m) => {
    min.x = Math.min(min.x, m.start.x, m.end.x);
    min.y = Math.min(min.y, m.start.y, m.end.y);
    max.x = Math.max(max.x, m.start.x, m.end.x);
    max.y = Math.max(max.y, m.start.y, m.end.y);
  });
  return {
    x: min.x * scale,
    y: -max.y * scale,
    width: (max.x - min.x) * scale,
    height: (max.y - min.y) * scale,
  };
}
function parseDxfText(text) {
  const helper = new Helper(text);

  const entities = helper.parsed.entities;
  const groups = helper.groups;
  const scale = 12.0; // convert to inches (currently each unit is a foot)
  const viewBox = getViewBox(entities, scale);
  const panels = scaleSets(groupify4(groups['Modules']), scale);
  const setbacks = scaleSets(groupify4(groups['Setbacks']), scale);
  const buildings = scaleSets(groupify4(groups['Buildings']), scale).map(
    (building, index) => {
      // Add Centroid to each building
      const coords = [
        building.lines
          .map((line) => [
            [line.start.x, line.start.y],
            [line.end.x, line.end.y],
          ])
          .flat(1),
      ];
      const buildingPolygon = polygon(coords);
      const center = centroid(buildingPolygon);
      return {
        ...building,
        index: index + 1,
        panels: [],
        setbacks: [],
        center: {
          x: center.geometry.coordinates[0],
          y: center.geometry.coordinates[1],
        },
      };
    }
  );

  // IF panel is contained by building then add panel to building.panels
  panels.forEach((panel) => {
    const coords = [
      panel.lines
        .map((line) => [
          [line.start.x, line.start.y],
          [line.end.x, line.end.y],
        ])
        .flat(1),
    ];
    const panelPolygon = polygon(coords);
    buildings.forEach((building) => {
      const coords = [
        building.lines
          .map((line) => [
            [line.start.x, line.start.y],
            [line.end.x, line.end.y],
          ])
          .flat(1),
      ];
      const buildingPolygon = polygon(coords);
      const isContained = booleanContains(buildingPolygon, panelPolygon);
      if (isContained) {
        building.panels.push(panel);
      }
    });
  });
  // same thing for setbacks
  // setbacks.forEach(setback => {
  //   const coords = [setback.lines.map(line => [[line.start.x, line.start.y], [line.end.x, line.end.y]]).flat(1)]
  //   const setbackPolygon = polygon(coords)
  //   buildings.forEach(building => {
  //     const coords = [building.lines.map(line => [[line.start.x, line.start.y], [line.end.x, line.end.y]]).flat(1)]
  //     const buildingPolygon = polygon(coords)
  //     const isContained = booleanContains(buildingPolygon, setbackPolygon)
  //     if (isContained) {
  //       building.setbacks.push(setback)
  //     }
  //   })
  // })

  return { viewBox, buildings, setbacks, panels };
}
function toSVG({ viewBox, buildings }) {
  return `
  <style>
  .svg-design patter {
    overflow: hidden;
  }
  .svg-panel {
  }
  /*.svg-panel:hover {
    fill:yellow;
  }*/
  .svg-building {
    stroke: #fff;
    stroke-width:1px;
    fill:#e3e2e2;
  }
  /*.svg-building:hover {
    fill: gray;
  }*/
  .svg-setback {
    stroke: red;
    stroke-width:1px;
    stroke-dasharray: 10,10;
    fill:none;
  }
  .svg-active {
    stroke:yellow;
  }
  </style>
  <?xml version="1.0"?>
  <svg class="svg-design"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1"
    preserveAspectRatio="xMinYMin meet"
    viewBox="${viewBox.x} ${viewBox.y} ${viewBox.width} ${viewBox.height}"
    width="100%" height="100%"
  >
    <defs>
      <pattern id="panel-pattern" x="0" y="0" width="5" height="5" patternUnits="userSpaceOnUse" patternTransform="rotate(-120)">
        <rect x="0" y="0" width="5" height="5" fill="#131832"></rect>
        <rect x="0" y="0" width="1" height="1" fill="#ffffff" class="panel-pattern-dot"></rect>
      </pattern>
    </defs>
    <g stroke="#000000" stroke-width="0.1%" fill="none" transform="matrix(1,0,0,-1,0,0)">
      <g id="buildings">
        ${buildings
          .map((building) => {
            return `
          <g class="building">
            <path id="building-${
              building.index
            }" class="svg-building" d="M ${building.lines
              .map(
                (line) =>
                  `${line.start.x} ${line.start.y} L ${line.end.x} ${line.end.y}`
              )
              .join(' L ')} Z" />
            <g id="building-panels-${building.index}" class="panel-group">
              ${building.panels
                .map((panel) => {
                  return `<path id="panel-${
                    panel.index
                  }" class="svg-panel" d="M ${panel.lines
                    .map(
                      (line) =>
                        `${line.start.x} ${line.start.y} L ${line.end.x} ${line.end.y}`
                    )
                    .join(' L ')} Z" fill="url(#panel-pattern)" />`;
                })
                .join('\n')}
            </g>
            ${
              building.panels.length > 0 && building.center && building.center.x
                ? `
              <g id="building-center-${building.index}">
                <circle cx="${building.center.x}" cy="${
                    building.center.y
                  }" r="20" style="fill:#fff;stroke:red;stroke-width:1px; " />
                <text text-anchor="middle" x="${building.center.x}" y="${
                    building.center.y * -1
                  }" dy="0.4em" stroke="red" fill="red" style="font-size:30px;" transform="matrix(1,0,0,-1,0,0)">${
                    building.index
                  }</text>
              </g>
            `
                : ''
            }
          </g>
          `;
          })
          .join('\n')}
      </g>
    </g>
  </svg>`;
}

function toFields({ buildings }) {
  const planes = buildings.filter((b) => b.panels.length > 0);
  return planes
    .map((plane) => {
      return `
      <div class="form-group row">
        <label class="col-sm-3 col-form-label">Roof Plane #${plane.index}</label>
        <div class="col-sm-9">
          <input type="file" name="roof_characteristics_${plane.index}" class="form-control" />
        </div>
      </div>
    `;
    })
    .join('\n');
}

export default class extends Controller {
  static targets = ['file', 'svg', 'fields'];

  connect() {
    this.fileTarget.addEventListener('change', (event) => {
      const files = event.target.files;
      if (files.length > 0) {
        const file = files[0];
        const reader = new FileReader();
        reader.onload = (e) => {
          const text = e.target.result;
          const { viewBox, buildings } = parseDxfText(text);

          // TODO:
          // const helper = new Helper(text)
          // window.helper = helper
          // console.log(helper)
          this.fieldsTarget.innerHTML = toFields({ buildings });
          this.svgTarget.innerHTML = toSVG({ viewBox, buildings });
        };
        reader.readAsText(file);
      }
    });
  }
}
